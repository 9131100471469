@import "_variables.scss";
@import "_reset.scss";

body {
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;

    &.reg-user {
        display: flex;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

h1 {
    font-size: 2em;
}
h2 {
    font-size: 1.7em;
}
h3 {
    font-size: 1.5em;
}
h4 {
    font-size: 1.3em;
}
h5 {
    font-size: 1.1em;
}

strong,
b {
    font-weight: bold;
}

.error {
    color: $error-color;
}

.error-box {
    background: $error-bg;
    border: 1px $error-color solid;
    color: $error-color;
    margin: 1em 0;
    padding: 0.5em;
}

.success-box {
    background: $success-bg;
    border: 1px $success-color solid;
    color: $success-color;
    margin: 1em 0;
    padding: 0.5em;
}

button {
    background: $button_color;
    border: none;
    color: #fff;
    outline: none;
    padding: 0.8em 1em;
    width: 25%;

    &:hover {
        background: $button_hover;
        cursor: pointer;
    }

    &:disabled {
        background: $button_disabled;
        cursor: default;
    }
}

button.link {
    background: none;
    border: none;
    color: $link_color;
    font-size: 16px;
    line-height: 100%;
    padding: 0;
    width: auto;

    &:hover {
        background: none;
        color: $link_hover;
        text-decoration: underline;
    }
}

.modal .modal-content {
    background: #282c34;
    color: #fff;
    padding: 2em;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    z-index: 10;

    .modal-header {
        align-items: center;
        display: flex;
        font-size: 24px;
        justify-content: space-between;
        margin-bottom: 1em;
        padding-bottom: 1em;

        span {
            color: #999;
            cursor: pointer;
            font-size: 20px;

            &:hover {
                color: #fff;
            }
        }
    }

    button {
        margin-right: 1em;
    }
}

.overlay {
    background: rgba(0, 0, 0, 0.5);
    display: none;
    cursor: pointer;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
}

.hide {
    display: none;
}

.show {
    display: block;
}

header {
    background-color: $nav_bg;
    position: relative;

    > h1 {
        a {
            color: #fff;

            &:hover {
                color: $button_hover;
            }
        }
    }

    a,
    button,
    button.link {
        color: #fff;

        &:hover {
            color: $button_hover;
        }
    }
}

.guest-user > header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.5em 2em;
}

.reg-user header {
    width: 18%;

    h1 {
        padding: 1em 0;
        text-align: center;
    }

    nav {
        ul {
            background: $nav_bg_secondary;
            border: 1px #666 solid;
            border-width: 1px 0;

            li {
                a,
                button,
                button.link {
                    border-left: 5px $button_color solid;
                    display: block;
                    font-size: 16px;
                    line-height: 100%;
                    padding: 1em;
                    text-align: left;
                    width: 100%;

                    &:hover {
                        background: $nav_bg_hover;
                        border-left: 5px $button_hover solid;
                        cursor: pointer;
                        text-decoration: none;
                    }
                }

                ul {
                    background: $nav_bg;
                    border: none;
                    max-height: 400px;
                    overflow-y: auto;

                    li button {
                        background: $nav_bg;
                        border-left: 5px $button_color solid;
                    }
                }

                button.active,
                a.active {
                    background: $button_color;
                }
            }
        }
    }
}

.guest-user .content {
    width: 100%;
}

.reg-user .content {
    width: 82%;
}

#secondary-nav {
    background: $second_nav_bg;
    height: 48px;
    width: 100%;

    ul {
        display: flex;

        li {
            padding: 1em 2em;

            a {
                color: $link_color;

                &:hover {
                    color: $link_hover;
                }
            }
        }
    }
}

#content {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 48px);
    padding: 1em 2em;
}

#version {
    .bottom p {
        color: #666;
        font-size: 0.9em;
        padding: 0.5em;
        position: absolute;
        left: 50%;
        bottom: 0;
        text-align: center;
        transform: translateX(-50%);
    }
}
